import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const LogoStyled = styled(Link)`
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  span.logo {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px!important;
    color: #000000;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  span.desc {
    display: block;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
`;

const Logo = () => {
  return (
    <LogoStyled to="/">
      <span className="logo">HustleBox</span>
      <span className="desc">Web Dev Shop</span>
    </LogoStyled>
  )
};

export default Logo;
