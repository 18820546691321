import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  a {
    margin: 0 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }

    @media (max-width: 768px) {
      margin: 0;
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 3px;
      line-height: 48px;
    }
  }
`;
