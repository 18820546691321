import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FiFacebook, FiTwitter, FiInstagram, FiMail } from 'react-icons/fi';

import { Container, FooterLinks } from './footer.css';

import IconContainer from '../iconContainer/iconContainer';
import Logo from '../navbar/Logo';

const Footer = ({ title }) => {
  return (
    <Container>
      <div>
        <Logo />
        {/* <div className="d-flex flex-row align-items-center justify-content-between mt-4">
          <Link to="/" className="mr-4"><IconContainer><FiFacebook size="18px" color="ffffff" /></IconContainer></Link>
          <Link to="/" className="mr-4"><IconContainer><FiTwitter size="18px" color="ffffff" /></IconContainer></Link>
          <Link to="/" className="mr-4"><IconContainer><FiInstagram size="18px" color="ffffff" /></IconContainer></Link>
          <Link to="/" className="mr-4"><IconContainer><FiMail size="18px" color="ffffff" /></IconContainer></Link>
        </div> */}
      </div>
      <FooterLinks>
        <Link to="/services">What I Do</Link>
        <Link to="/services/website">Need a Website?</Link>
        <Link to="/contact">Contact me</Link>
      </FooterLinks>
    </Container>
  );
};

Footer.propTypes = {
  title: PropTypes.string.isRequired
};

export default Footer;
