import styled from 'styled-components';

export const Container = styled.footer`
  display: grid;
  grid-template-columns: 200px 500px;
  grid-column-gap: 24px;
  align-items: flex-start;
  justify-content: space-between;
  margin: 100px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    grid-template-columns: 200px;
    grid-row-gap: 50px;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #757575;
    text-transform: uppercase;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
    @media (max-width: 768px) {
      text-align: left;
    }
  }
`;
